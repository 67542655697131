exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-owner-manager-js": () => import("./../../../src/pages/business-owner-manager.js" /* webpackChunkName: "component---src-pages-business-owner-manager-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-drupal-js": () => import("./../../../src/pages/drupal.js" /* webpackChunkName: "component---src-pages-drupal-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-google-ads-js": () => import("./../../../src/pages/google-ads.js" /* webpackChunkName: "component---src-pages-google-ads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-node-blog-path-alias-js": () => import("./../../../src/pages/{nodeBlog.path__alias}.js" /* webpackChunkName: "component---src-pages-node-blog-path-alias-js" */),
  "component---src-pages-node-portfolio-client-path-alias-js": () => import("./../../../src/pages/{nodePortfolioClient.path__alias}.js" /* webpackChunkName: "component---src-pages-node-portfolio-client-path-alias-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-ux-ui-js": () => import("./../../../src/pages/ux-ui.js" /* webpackChunkName: "component---src-pages-ux-ui-js" */)
}

